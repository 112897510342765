.banner {
  height: 400px;
  background-color: #3d3d3d;
  color: white;
  padding: 52px;
  margin-bottom: -100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  align-content: center;
}

.bannerWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  align-content: center;
  position: relative;
}

.firstBannerSection {
  width: 100%;
  display: none;
}

.middleBannerSection {
  width: 100%;
}

.lastBannerSection {
  margin-top: 16px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-content: center;
  position: relative;
}

.title {
  font: 20px;
  margin: 0;
}

.hide-mobile {
  display: none;
}

.bannerSpace {
  display: none;
}

@media only screen and (min-width: 650px) {
  .banner {
    height: 240px;
    background-color: #3d3d3d;
    color: white;
    padding: 40px 40px;
    margin-bottom: -80px;
  }

  .bannerWrap {
    max-width: none;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    align-content: center;
    position: relative;
  }

  .lastBannerSection {
    margin-top: 0px;
    width: 20%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    align-content: center;
    position: relative;
  }

  .hide-mobile {
    display: inline;
  }
}

@media only screen and (min-width: 800px) {
  .banner {
    height: 260px;
    background-color: #3d3d3d;
    color: white;
    padding: 40px 40px;
    margin-bottom: -100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    align-content: center;
  }

  .firstBannerSection {
    margin-top: 0px;
    width: 20%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    align-content: center;
    position: relative;
  }
}
