@import url(https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,400;0,700;1,400&display=swap);
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: "Nunito", "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f3f3;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
  position: relative;
  min-height: 100vh;
}

.body {
  max-width: 1080px;
  margin: 32px auto 32px;
}

.flexWrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
}

.dRelative {
  display: relative;
}

.flexWrapSpaceBet {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-content: center;
  position: relative;
}

.flexWrapStartCol {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  align-content: center;
  position: relative;
}

.flexWrapSpaceBetCol {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  align-content: center;
  position: relative;
}

.flexWrapSpaceEven {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  align-content: space-between;
  position: relative;
}

.flexWrapStart {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  align-content: space-between;
  position: relative;
  flex-wrap: wrap;
}

.flexWrapEnd {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  align-content: space-between;
  position: relative;
}

.offset {
  position: relative;
  top: -200px;
}

.floatTR {
  position: absolute;
  top: 0;
  right: 0;
}
/* grid */

.tricontainer {
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  display: -ms-grid;
  display: grid;
  grid-gap: 1em;
  margin: 0px auto;
  max-width: 1560px;
}

.minMargin {
  margin: 0px 1em;
}

.w-third {
  width: 33%;
}

.w-twen {
  width: 20%;
}

.w-hun {
  width: 100%;
}

.border {
  border-radius: 5px;
  border: 1px solid black;
}

.card {
  background-color: white;
  border: 1px solid #f2f3f3;
  border-radius: 5px;
}

/* Padding and margins */

.sPad {
  padding: 16px 24px;
}

.slPad {
  padding: 8px 16px;
}

.space {
  margin: 8px 0px;
}

.lmargin {
  margin: 8px;
}

@media only screen and (min-width: 650px) {
  .hide-mobile {
    display: inline;
  }
  .tricontainer {
    -ms-grid-columns: 1fr 1em 1fr 1em 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    display: -ms-grid;
    display: grid;
    grid-gap: 1em;
    margin: 0px auto;
    max-width: 1560px;
  }
}

@media only screen and (min-width: 1080px) {
  .tricontainer {
    -ms-grid-columns: 1fr 1em 1fr 1em 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    display: -ms-grid;
    display: grid;
    grid-gap: 1em;
    margin: 0px auto;
    max-width: 1560px;
  }
}

.tag {
  border: 1px solid grey;
  width: 100%;
  height: 40px;
  border-radius: 5px;
  margin: 8px 0px;
  font-size: 16px;
  text-align: left;
  padding: 8px 16px;
  border-color: 1px solid grey;
  color: grey;
}

.helpText {
  font-size: 10px;
  color: red;
}

.cSButton {
  border-radius: 5px;
  background-color: transparent;
  box-shadow: none;
  width: 24px;
  height: 24px;
  /* border-style: hidden; */
  cursor: pointer;
}

.cSButton:hover {
  margin: 4px;
  background-color: #feb116;
  box-shadow: none;
  width: 24px;
  height: 24px;
  color: white;
  /* border-style: hidden; */
  cursor: pointer;
}

.sButton {
  margin-top: 8px;
  margin-bottom: 8px;
  border-radius: 5px;
  background-color: transparent;
  color: #feb116;
  width: 40px;
  height: 40px;
  box-shadow: none;
  /* border-style: hidden; */
  border: 1px solid #feb116;
  cursor: pointer;
  font-size: 20px;
}

.sButton:hover {
  background-color: #fea116;
  color: white;
}

.active {
  background-color: #fea116;
  color: white;
}

.aButton {
  margin: 8px;
  border-radius: 5px;
  background-color: #feb116;
  color: white;
  width: 100px;
  height: 40px;
  box-shadow: none;
  /* border-style: hidden; */
  border: 1px solid #feb116;
  cursor: pointer;
  font-size: 20px;
}

.aButton:hover {
  background-color: #f79502;
  color: white;
}

.aSButton {
  margin: 8px;
  border-radius: 5px;
  background-color: #feb116;
  color: white;
  width: 40px;
  height: 40px;
  box-shadow: none;
  /* border-style: hidden; */
  border: 1px solid #feb116;
  cursor: pointer;
  font-size: 20px;
}

.aSButton:hover {
  background-color: #f79502;
  color: white;
}

.helpButton:hover {
  color: #feb116;
}

.picButton {
  margin: 16px;
  border-radius: 5px;
  background-color: #feb116;
  color: white;
  width: 150px;
  height: 40px;
  box-shadow: none;
  border: 1px solid #feb116;
  cursor: pointer;
  font-size: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.picButton:hover {
  background-color: #f79502;
  color: white;
}

.point {
  cursor: pointer;
}

.reportBox:focus {
  outline: none;
}

.pointer {
  cursor: pointer;
}

.overHang {
  z-index: 0;
}

h1 {
  font-size: 32px;
  font-weight: 400;
  margin-bottom: 24px;
}

h5 {
  font-size: 19px;
  font-weight: 400;
}

h3 {
  font-size: 20px;
  font-weight: 400;
}

h4 {
  font-size: 18px;
}

h5 {
  margin-top: 16px;
  margin-bottom: 16px;
}

h6 {
  font-size: 18px;
  font-weight: 400;
}

.subHead {
  font-size: 16px;
  text-align: center;
  margin: 0px;
}

p,
ol,
ul {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  /* text-align: left; */
}

.left-align {
  text-align: left;
}

.cen-align {
  text-align: center;
}

.bold {
  font-weight: bold;
}

.pColor {
  color: #feb116;
}

.i {
  font-style: italic;
}

.hover:hover {
  color: #feb116;
}

.iconScale {
  font-size: 22px;
  margin-left: 4px;
}

label {
  color: black;
  font-size: 14px;
  text-align: left;
  padding-top: 8px;
  padding-bottom: 8px;
}

.inputWrap {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 16px;
  margin-left: 0px;
  width: 100%;
  max-width: 500px;
}

input {
  cursor: text;
}

input {
  background-color: #ffffff;
  width: 100%;
  border-style: solid;
  border-color: #f5f5f5;
  border-radius: 4px;
  padding: 12px;
  font-size: 14px;
  color: #666666;
  border-width: 2px;
  font-family: "Nunito", "Roboto", sans-serif;
}

@media only screen and (min-width: 600px) {
}

select {
  background-color: #ffffff;
  width: 100%;
  border-style: solid;
  border-color: #f5f5f5;
  border-radius: 4px;
  padding: 12px;
  font-size: 14px;
  color: #666666;
  border-width: 2px;
  font-family: "Nunito", "Roboto", sans-serif;
}

select {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
}

.modal {
  position: absolute;
  z-index: 500;
  border-radius: 20px;
  left: 5%;
  top: 5vh;
  width: 90%;
  transition: all 0.3s ease-out;
  background-color: white;
  border: 1px solid var(--color-border);
}

.topRight {
  position: absolute;
  top: 16px;
  right: 16px;
}

.hide {
  display: none;
}

.backdrop {
  width: 100%;
  min-height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.8);
}

@media (min-width: 330px) {
  .modal {
    position: absolute;
    z-index: 500;
    border-radius: 20px;
    left: 10%;
    top: 5vh;
    width: 80%;
    transition: all 0.3s ease-out;
    border: 1px solid var(--color-border);
  }
}

@media (min-width: 600px) {
  .modal {
    width: 500px;
    left: calc(50% - 250px);
  }
}

textarea {
  background-color: #ffffff;
  width: 100%;
  border-style: none;
  border-color: #f5f5f5;
  border-radius: 4px;
  padding: 12px;
  font-size: 16px;
  font-weight: bold;
  color: #666666;
  border-width: 2px;
  font-family: "Nunito", "Roboto", sans-serif;
  margin: 32px, 0px;
  min-height: 480px;
}

textarea {
}

.Tag-label {
  overflow: hidden;
  white-space: nowrap;
  padding-left: 12px;
  padding-right: 12px;
  text-overflow: ellipsis;
}

.Tag-deleteIcon {
  color: rgba(0, 0, 0, 0.26);
  width: 22px;
  cursor: pointer;
  height: 22px;
  margin: 0 5px 0 -6px;
  -webkit-tap-highlight-color: transparent;
}

.Tag {
  color: rgba(0, 0, 0, 0.87);
  border: none;
  cursor: default;
  height: 32px;
  display: inline-flex;
  outline: 0;
  padding: 0;
  font-size: 0.8125rem;
  box-sizing: border-box;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  align-items: center;
  white-space: nowrap;
  border-radius: 5px;
  vertical-align: middle;
  justify-content: center;
  text-decoration: none;
  background-color: #e0e0e0;
  margin: 8px 4px 4px;
}

.inline input {
  background-color: #ffffff;
  width: 80%;
  border-style: solid;
  border-color: #f5f5f5;
  border-radius: 4px;
  padding: 12px;
  font-size: 14px;
  color: #666666;
  border-width: 2px;
}

input[type="range"] {
  border: none;
  -webkit-appearance: none;
  width: 100%;
  margin: 8px 0px 4px;
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  animate: 0.2s;
  background: #feb116;
  border-radius: 25px;
}

input[type="range"]::-ms-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  animate: 0.2s;
  background: #feb116;
  border-radius: 25px;
}

input[type="range"]::-moz-range-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  animate: 0.2s;
  background: #feb116;
  border-radius: 25px;
}

input[type="range"]::-ms-thumb {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 1);
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -8px;
}

input[type="range"]::-webkit-slider-thumb {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 1);
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -8px;
}

input[type="range"]::-moz-range-thumb {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 1);
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -8px;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #feb116;
}

input[type="range"]::-ms-tooltip {
  display: none;
}

.range-wrap {
  width: 500px;
  position: relative;
}
.range-value {
  position: absolute;
  top: -50%;
}

.sliderLabels p {
  margin: 0;
  font-size: 10px;
}

.sliderLabels p:first-child {
  text-align: left;
}

.sliderLabels p:last-child {
  text-align: right;
}

input[type="range"]::-ms-fill-lower {
  background: #feb116;
}

input[type="range"]::-ms-fill-upper {
  background: #feb116;
}

/* The container */
.checkcontainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 0px;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #666;
  width: 100%;
}

/* Hide the browser's default checkbox */
.checkcontainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 4px;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: #eee;
  border-radius: 5px;
}

/* On mouse-over, add a grey background color */
.checkcontainer:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkcontainer input:checked ~ .checkmark {
  background-color: #feb116;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkcontainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkcontainer .checkmark:after {
  left: 8px;
  top: 5px;
  width: 8px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

@media only screen and (min-width: 1100px) {
  .checkcontainer {
    width: 50%;
  }
}

.footer {
  bottom: 0;
  width: 100%;
  margin-top: -40px;
  height: 170px !important;
  border-top: 1px solid #ebecec;
  background-color: white;
  padding-left: 32px;
  padding-right: 32px;
  padding: 32px;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: row;
  display: flex;
  background-color: #3d3d3d;
  color: white;
  z-index: -1;
}

.footer p,
.footer a {
  font-size: 12px;
  padding: 0;
  margin: 0;
}

.footerContent {
  padding-left: 32px;
  padding-right: 32px;
  height: 60px !important;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  display: flex;
}

@media only screen and (min-width: 600px) {
  .footerContent {
    max-width: 80%;
    margin-left: 10%;
    margin-right: 10%;
  }
}

@media only screen and (min-width: 900px) {
  .footerContent {
    max-width: 50%;
    margin-left: 25%;
    margin-right: 25%;
  }
}

.banner {
  height: 400px;
  background-color: #3d3d3d;
  color: white;
  padding: 52px;
  margin-bottom: -100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  align-content: center;
}

.bannerWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  align-content: center;
  position: relative;
}

.firstBannerSection {
  width: 100%;
  display: none;
}

.middleBannerSection {
  width: 100%;
}

.lastBannerSection {
  margin-top: 16px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-content: center;
  position: relative;
}

.title {
  font: 20px;
  margin: 0;
}

.hide-mobile {
  display: none;
}

.bannerSpace {
  display: none;
}

@media only screen and (min-width: 650px) {
  .banner {
    height: 240px;
    background-color: #3d3d3d;
    color: white;
    padding: 40px 40px;
    margin-bottom: -80px;
  }

  .bannerWrap {
    max-width: none;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    align-content: center;
    position: relative;
  }

  .lastBannerSection {
    margin-top: 0px;
    width: 20%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    align-content: center;
    position: relative;
  }

  .hide-mobile {
    display: inline;
  }
}

@media only screen and (min-width: 800px) {
  .banner {
    height: 260px;
    background-color: #3d3d3d;
    color: white;
    padding: 40px 40px;
    margin-bottom: -100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    align-content: center;
  }

  .firstBannerSection {
    margin-top: 0px;
    width: 20%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    align-content: center;
    position: relative;
  }
}

.modal {
  position: absolute;
  z-index: 500;
  border-radius: 20px;
  left: 5%;
  top: 5vh;
  width: 90%;
  transition: all 0.3s ease-out;
  background-color: white;
  border: 1px solid var(--color-border);
}

.topRight {
  position: absolute;
  top: 16px;
  right: 16px;
}

.hide {
  display: none;
}

.backdrop {
  width: 100%;
  min-height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.8);
}

@media (min-width: 330px) {
  .modal {
    position: absolute;
    z-index: 500;
    border-radius: 20px;
    left: 10%;
    top: 5vh;
    width: 80%;
    transition: all 0.3s ease-out;
    border: 1px solid var(--color-border);
  }
}

@media (min-width: 600px) {
  .modal {
    width: 500px;
    left: calc(50% - 250px);
  }
}

