.modal {
  position: absolute;
  z-index: 500;
  border-radius: 20px;
  left: 5%;
  top: 5vh;
  width: 90%;
  transition: all 0.3s ease-out;
  background-color: white;
  border: 1px solid var(--color-border);
}

.topRight {
  position: absolute;
  top: 16px;
  right: 16px;
}

.hide {
  display: none;
}

.backdrop {
  width: 100%;
  min-height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.8);
}

@media (min-width: 330px) {
  .modal {
    position: absolute;
    z-index: 500;
    border-radius: 20px;
    left: 10%;
    top: 5vh;
    width: 80%;
    transition: all 0.3s ease-out;
    border: 1px solid var(--color-border);
  }
}

@media (min-width: 600px) {
  .modal {
    width: 500px;
    left: calc(50% - 250px);
  }
}
