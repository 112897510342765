.App {
  text-align: center;
  position: relative;
  min-height: 100vh;
}

.body {
  max-width: 1080px;
  margin: 32px auto 32px;
}
