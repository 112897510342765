.flexWrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
}

.dRelative {
  display: relative;
}

.flexWrapSpaceBet {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-content: center;
  position: relative;
}

.flexWrapStartCol {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  align-content: center;
  position: relative;
}

.flexWrapSpaceBetCol {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  align-content: center;
  position: relative;
}

.flexWrapSpaceEven {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  align-content: space-between;
  position: relative;
}

.flexWrapStart {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  align-content: space-between;
  position: relative;
  flex-wrap: wrap;
}

.flexWrapEnd {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  align-content: space-between;
  position: relative;
}

.offset {
  position: relative;
  top: -200px;
}

.floatTR {
  position: absolute;
  top: 0;
  right: 0;
}
/* grid */

.tricontainer {
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  display: -ms-grid;
  display: grid;
  grid-gap: 1em;
  margin: 0px auto;
  max-width: 1560px;
}

.minMargin {
  margin: 0px 1em;
}

.w-third {
  width: 33%;
}

.w-twen {
  width: 20%;
}

.w-hun {
  width: 100%;
}

.border {
  border-radius: 5px;
  border: 1px solid black;
}

.card {
  background-color: white;
  border: 1px solid #f2f3f3;
  border-radius: 5px;
}

/* Padding and margins */

.sPad {
  padding: 16px 24px;
}

.slPad {
  padding: 8px 16px;
}

.space {
  margin: 8px 0px;
}

.lmargin {
  margin: 8px;
}

@media only screen and (min-width: 650px) {
  .hide-mobile {
    display: inline;
  }
  .tricontainer {
    -ms-grid-columns: 1fr 1em 1fr 1em 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    display: -ms-grid;
    display: grid;
    grid-gap: 1em;
    margin: 0px auto;
    max-width: 1560px;
  }
}

@media only screen and (min-width: 1080px) {
  .tricontainer {
    -ms-grid-columns: 1fr 1em 1fr 1em 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    display: -ms-grid;
    display: grid;
    grid-gap: 1em;
    margin: 0px auto;
    max-width: 1560px;
  }
}
