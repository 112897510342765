.Tag-label {
  overflow: hidden;
  white-space: nowrap;
  padding-left: 12px;
  padding-right: 12px;
  text-overflow: ellipsis;
}

.Tag-deleteIcon {
  color: rgba(0, 0, 0, 0.26);
  width: 22px;
  cursor: pointer;
  height: 22px;
  margin: 0 5px 0 -6px;
  -webkit-tap-highlight-color: transparent;
}

.Tag {
  color: rgba(0, 0, 0, 0.87);
  border: none;
  cursor: default;
  height: 32px;
  display: inline-flex;
  outline: 0;
  padding: 0;
  font-size: 0.8125rem;
  box-sizing: border-box;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  align-items: center;
  white-space: nowrap;
  border-radius: 5px;
  vertical-align: middle;
  justify-content: center;
  text-decoration: none;
  background-color: #e0e0e0;
  margin: 8px 4px 4px;
}
