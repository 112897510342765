.inline input {
  background-color: #ffffff;
  width: 80%;
  border-style: solid;
  border-color: #f5f5f5;
  border-radius: 4px;
  padding: 12px;
  font-size: 14px;
  color: #666666;
  border-width: 2px;
}
