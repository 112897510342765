input[type="range"] {
  border: none;
  -webkit-appearance: none;
  width: 100%;
  margin: 8px 0px 4px;
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  animate: 0.2s;
  background: #feb116;
  border-radius: 25px;
}

input[type="range"]::-ms-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  animate: 0.2s;
  background: #feb116;
  border-radius: 25px;
}

input[type="range"]::-moz-range-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  animate: 0.2s;
  background: #feb116;
  border-radius: 25px;
}

input[type="range"]::-ms-thumb {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 1);
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -8px;
}

input[type="range"]::-webkit-slider-thumb {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 1);
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -8px;
}

input[type="range"]::-moz-range-thumb {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 1);
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -8px;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #feb116;
}

input[type="range"]::-ms-tooltip {
  display: none;
}

.range-wrap {
  width: 500px;
  position: relative;
}
.range-value {
  position: absolute;
  top: -50%;
}

.sliderLabels p {
  margin: 0;
  font-size: 10px;
}

.sliderLabels p:first-child {
  text-align: left;
}

.sliderLabels p:last-child {
  text-align: right;
}

input[type="range"]::-ms-fill-lower {
  background: #feb116;
}

input[type="range"]::-ms-fill-upper {
  background: #feb116;
}
