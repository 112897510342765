h1 {
  font-size: 32px;
  font-weight: 400;
  margin-bottom: 24px;
}

h5 {
  font-size: 19px;
  font-weight: 400;
}

h3 {
  font-size: 20px;
  font-weight: 400;
}

h4 {
  font-size: 18px;
}

h5 {
  margin-top: 16px;
  margin-bottom: 16px;
}

h6 {
  font-size: 18px;
  font-weight: 400;
}

.subHead {
  font-size: 16px;
  text-align: center;
  margin: 0px;
}

p,
ol,
ul {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  /* text-align: left; */
}

.left-align {
  text-align: left;
}

.cen-align {
  text-align: center;
}

.bold {
  font-weight: bold;
}

.pColor {
  color: #feb116;
}

.i {
  font-style: italic;
}

.hover:hover {
  color: #feb116;
}

.iconScale {
  font-size: 22px;
  margin-left: 4px;
}
