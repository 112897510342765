textarea {
  background-color: #ffffff;
  width: 100%;
  border-style: none;
  border-color: #f5f5f5;
  border-radius: 4px;
  padding: 12px;
  font-size: 16px;
  font-weight: bold;
  color: #666666;
  border-width: 2px;
  font-family: "Nunito", "Roboto", sans-serif;
  margin: 32px, 0px;
  min-height: 480px;
}

textarea {
}
