.tag {
  border: 1px solid grey;
  width: 100%;
  height: 40px;
  border-radius: 5px;
  margin: 8px 0px;
  font-size: 16px;
  text-align: left;
  padding: 8px 16px;
  border-color: 1px solid grey;
  color: grey;
}

.helpText {
  font-size: 10px;
  color: red;
}

.cSButton {
  border-radius: 5px;
  background-color: transparent;
  box-shadow: none;
  width: 24px;
  height: 24px;
  /* border-style: hidden; */
  cursor: pointer;
}

.cSButton:hover {
  margin: 4px;
  background-color: #feb116;
  box-shadow: none;
  width: 24px;
  height: 24px;
  color: white;
  /* border-style: hidden; */
  cursor: pointer;
}

.sButton {
  margin-top: 8px;
  margin-bottom: 8px;
  border-radius: 5px;
  background-color: transparent;
  color: #feb116;
  width: 40px;
  height: 40px;
  box-shadow: none;
  /* border-style: hidden; */
  border: 1px solid #feb116;
  cursor: pointer;
  font-size: 20px;
}

.sButton:hover {
  background-color: #fea116;
  color: white;
}

.active {
  background-color: #fea116;
  color: white;
}

.aButton {
  margin: 8px;
  border-radius: 5px;
  background-color: #feb116;
  color: white;
  width: 100px;
  height: 40px;
  box-shadow: none;
  /* border-style: hidden; */
  border: 1px solid #feb116;
  cursor: pointer;
  font-size: 20px;
}

.aButton:hover {
  background-color: #f79502;
  color: white;
}

.aSButton {
  margin: 8px;
  border-radius: 5px;
  background-color: #feb116;
  color: white;
  width: 40px;
  height: 40px;
  box-shadow: none;
  /* border-style: hidden; */
  border: 1px solid #feb116;
  cursor: pointer;
  font-size: 20px;
}

.aSButton:hover {
  background-color: #f79502;
  color: white;
}

.helpButton:hover {
  color: #feb116;
}

.picButton {
  margin: 16px;
  border-radius: 5px;
  background-color: #feb116;
  color: white;
  width: 150px;
  height: 40px;
  box-shadow: none;
  border: 1px solid #feb116;
  cursor: pointer;
  font-size: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.picButton:hover {
  background-color: #f79502;
  color: white;
}

.point {
  cursor: pointer;
}

.reportBox:focus {
  outline: none;
}

.pointer {
  cursor: pointer;
}

.overHang {
  z-index: 0;
}
