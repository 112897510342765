.footer {
  bottom: 0;
  width: 100%;
  margin-top: -40px;
  height: 170px !important;
  border-top: 1px solid #ebecec;
  background-color: white;
  padding-left: 32px;
  padding-right: 32px;
  padding: 32px;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: row;
  display: flex;
  background-color: #3d3d3d;
  color: white;
  z-index: -1;
}

.footer p,
.footer a {
  font-size: 12px;
  padding: 0;
  margin: 0;
}

.footerContent {
  padding-left: 32px;
  padding-right: 32px;
  height: 60px !important;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  display: flex;
}

@media only screen and (min-width: 600px) {
  .footerContent {
    max-width: 80%;
    margin-left: 10%;
    margin-right: 10%;
  }
}

@media only screen and (min-width: 900px) {
  .footerContent {
    max-width: 50%;
    margin-left: 25%;
    margin-right: 25%;
  }
}
